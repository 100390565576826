import React from "react"
const Video = ({ src, title, ...props }) => (
  <video
    src={src}
    preload="auto"
    title={title}
    style={{ width: "100%", height: "auto" }}
    loop
    muted
    playsInline
    autoPlay
  />
)
export default Video
