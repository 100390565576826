import React from "react"
import TeX from "@matejmazur/react-katex"
import { MDXProvider } from "@mdx-js/react"
import Video from "./Video"

const components = {
  div: props => {
    if (props.className && props.className.includes("math-display")) {
      import("katex/dist/katex.min.css")
      return <TeX block math={props.children} />
    }
    return <div {...props} />
  },
  span: props => {
    if (props.className && props.className.includes("math-inline")) {
      import("katex/dist/katex.min.css")
      return <TeX math={props.children} />
    }
    return <span {...props} />
  },
  a: props => {
    return (
      <a
        target={
          props.href.startsWith("#") ||
          props.href.startsWith("/") ||
          props.href.startsWith(".")
            ? undefined
            : "_blank"
        }
        {...props}
      />
    )
  },
  img: props => {
    if (props.src.endsWith(".mp4")) {
      return <Video src={props.src} title={props.alt} />
    }

    return <img {...props} />
  },
}

export default function MdXLayout(props) {
  return <MDXProvider components={components}>{props.children}</MDXProvider>
}
